import { Region, RegionShortnames } from '../../../helpers/platformapi/Region';
import { ListingDetails, AppLocation } from './types';

interface IntegrationOutputImages {
	resolution: string;
	imageName: string;
}

interface IntegrationOutputClientIDs {
	[env: string]: Array<string>;
}

interface IntegrationOutput {
	id: string;
	name: string;
	description: string;
	termsOfServiceUri: string;
	vendorName: string;
	vendorWebsiteUri: string;
	marketplaceUri: string;
	salesContactUri: string;
	privacyPolicyUri: string;
	supportContactUri: string;
	helpUri: string;
	provider: string;
	appHost: string;
	category: string;
	images: IntegrationOutputImages[];
	configPropertiesSchemaPath: string;
	configAdvancedSchemaPath: string;
	configOverrides: ConfigOverrides;
	products: Array<string>;
	env: Array<string>;
	vendorOAuthClientIds: IntegrationOutputClientIDs;
	userPermissions: Array<string>;
	nonInstallable: boolean;
	canRestore: boolean;
}

interface VendorOutput {
	vendor: string;
	orgId: string;
	region: string;
}

interface ConfigOverrides {
	properties: {
		sandbox: string;
		permissions?: string;
		displayType?: string;
		featureCategory?: string;
	};
	advanced: {
		icon: {
			vector: string;
		};
	};
}

const propertiesSchemaJSON = {
	$schema: 'http://json-schema.org/draft-04/schema#',
	type: 'object',
	title: ' Properties',
	description: 'Defines the basic configuration for ',
	properties: {
		url: {
			type: 'string',
			title: 'Application URL',
			description: 'The URL of the Application PureCloud should load.',
			pattern: '^https?://(localhost|[^-./?:#\\s][^./?:#\\s]*(\\.[^-./?:#\\s][^./?:#\\s]*)+)(:\\d{2,5})?([/?#][^\\s]*)?$',
			default: '',
		},
		displayType: {
			type: 'string',
			title: 'Application Type',
			description: 'Dictates the way the application will appear and function inside of PureCloud',
			enum: ['standalone', 'widget'],
			default: 'standalone',
		},
		featureCategory: {
			type: 'string',
			title: 'Application Category',
			description: 'Tailors application behavior to a specialized purpose.',
			enum: ['', 'directory', 'contactCenterInsights'],
			default: '',
		},
		sandbox: {
			type: 'string',
			title: 'Iframe Sandbox Options',
			description: 'Comma-separated list of limited HTML5 iframe sandbox options to control application permissions',
			pattern:
				'^(\\s*(allow-forms|allow-modals|allow-popups|allow-presentation|allow-same-origin|allow-scripts|allow-downloads)(\\s*,\\s*(allow-forms|allow-modals|allow-popups|allow-presentation|allow-same-origin|allow-scripts|allow-downloads))*\\s*)?$',
			default: 'allow-scripts,allow-same-origin,allow-forms,allow-modals',
		},
		permissions: {
			type: 'string',
			title: 'Iframe Feature/Permissions Policy',
			description:
				'Comma-separated list of features/permissions (See Permissions Policy on MDN) to control application permissions. Only the minimum required permissions should be added, as allowing permissions can open the app to security concerns.',
			pattern:
				'^(\\s*(camera|clipboard-write|display-capture|fullscreen|geolocation|microphone)(\\s*,\\s*(camera|clipboard-write|display-capture|fullscreen|geolocation|microphone))*\\s*)?$',
			default: '',
		},
		groupFilter: {
			$ref: '#/definitions/purecloudGroupIdFilterList',
		},
	},
	additionalProperties: false,
	required: ['url', 'displayType'],
	displayOrder: ['url', 'displayType', 'featureCategory', 'sandbox', 'permissions', 'groupFilter'],
	definitions: {
		purecloudGroupIdFilterList: {
			type: ['null', 'array'],
			title: 'Group Filtering',
			description:
				"Limit visibility of permissioned users to selected groups. Leaving blank will allow visibility for all users in this integration's defined permissions.",
			items: {
				type: 'string',
				title: 'Group GUID',
				pattern: '^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$',
			},
		},
	},
};

const advancedSchemaJSON = {
	$schema: 'http://json-schema.org/draft-04/schema#',
	type: 'object',
	title: ' Advanced Configuration',
	description: 'Defines the advanced configuration for ',
	properties: {
		i10n: {
			type: ['null', 'object'],
			title: 'Translations',
			description: 'Configuration object for application strings displayed within PureCloud',
			patternProperties: {
				'^[a-zA-Z]+(-[a-zA-Z]+)*$': {
					type: 'object',
					title: 'LangTag',
					description: 'BCP 47 Language Tag for the language you want to translate',
					properties: {
						name: {
							type: 'string',
							title: 'Translated Application Name',
							description: 'Translated application name in this LangTag',
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		lifecycle: {
			type: 'object',
			title: 'Application Lifecycle Configuration',
			description: 'Configuration relating to the lifecycle of the Application',
			properties: {
				ephemeral: {
					type: 'boolean',
					title: 'Ephemeral Application',
					description: 'Set to true to indicate that application is stateless or can be quickly restored',
				},
				hooks: {
					type: 'object',
					title: 'Lifecycle Hooks',
					description: 'Opt-in hooks to integrate with the PureCloud Apps lifecycle',
					properties: {
						bootstrap: {
							type: 'boolean',
							title: 'Enable Bootstap Hook',
							description:
								'When enabled, PureCloud emits an event when the application should bootstrap and will wait for a success event (will timeout)',
						},
						focus: {
							type: 'boolean',
							title: 'Enable Focus Hook',
							description: 'When enabled, PureCloud emits an event when an application receives focus',
						},
						blur: {
							type: 'boolean',
							title: 'Enable Blur Hook',
							description: 'When enabled, PureCloud emits an event when an application loses focus',
						},
						stop: {
							type: 'boolean',
							title: 'Enable Stop Hook',
							description:
								'When enabled, PureCloud emits an event when an application should tear down and will wait for a success event (will timeout)',
						},
					},
					additionalProperties: false,
				},
			},
			additionalProperties: false,
		},
		icon: { $ref: '#/definitions/appAssetCategory' },
		monochromicIcon: { $ref: '#/definitions/appAssetCategory' },
	},
	additionalProperties: false,
	definitions: {
		appAssetCategory: {
			type: ['null', 'object'],
			title: 'Image type (and size) specific Asset URLs',
			description:
				'Map of image types (and sizes) to asset URLs that are presented within PureCloud UIs based on category. (e.g. icon, monochromicIcon, etc.)',
			properties: {
				vector: {
					type: 'string',
					title: 'Vector URL',
					description: 'URL to a vector-based icon',
					pattern: '^https?://(localhost|[^-./?:#\\s][^./?:#\\s]*(\\.[^-./?:#\\s][^./?:#\\s]*)+)(:\\d{2,5})?([/?#][^\\s]*)?$',
				},
			},
			patternProperties: {
				'^\\d+x\\d+$': {
					type: 'string',
					title: 'Raster URL',
					description: 'URL to a raster-based icon (devicePixelRatio will be used)',
					pattern: '^https?://(localhost|[^-./?:#\\s][^./?:#\\s]*(\\.[^-./?:#\\s][^./?:#\\s]*)+)(:\\d{2,5})?([/?#][^\\s]*)?$',
				},
			},
			additionalProperties: false,
		},
	},
};

// Convert the region enum to AppFoundry formatted region string (eg. 'prod', 'prod-apne1')
const regionToAFRegion = (region: Region | undefined): string => {
	if (!region) return 'unknown';

	// (mypurecloud.com) is just 'prod'
	if (region === Region.us_east_1) return 'prod';

	return `prod-${RegionShortnames.get(region).toLowerCase()}`;
};

export function serializeIntegration(listing: ListingDetails): string {
	const appId = '{{app_id}}';

	const finalOutput: IntegrationOutput = {
		id: `premium-app-${appId}`,
		name: listing.name,
		description: listing.description,
		termsOfServiceUri: listing.tosURL,
		vendorName: listing.vendorName,
		vendorWebsiteUri: listing.vendorWebsite,
		marketplaceUri: listing.marketplaceURL || '',
		salesContactUri: listing.salesContactURL || '',
		privacyPolicyUri: listing.privacyPolicyURL || '',
		supportContactUri: listing.supportContactURL || '',
		helpUri: listing.helpURL,
		provider: 'clientapps',
		appHost: 'PureCloudClient',
		category: 'Client Apps',
		images: [
			{
				resolution: listing.installationAppIcon?.resolution || '',
				imageName: listing.installationAppIcon?.name || '',
			},
		],
		configPropertiesSchemaPath: `premium-app-${appId}-properties.json`,
		configAdvancedSchemaPath: `premium-app-${appId}-advanced.json`,
		configOverrides: {
			properties: {
				sandbox: listing.defaultSandboxOptions.join(','),
			},
			advanced: {
				icon: {
					vector: listing.hostedAppIcon,
				},
			},
		},
		products: ['{{TO BE COMPLETED}}'],
		env: ['dev', 'test', 'prod', 'fedramp'],
		vendorOAuthClientIds: {},
		userPermissions: ['integration:{{app_id}}:view'],
		nonInstallable: false,
		canRestore: true,
	};
	// Set vendorOAuthClientIds
	finalOutput.vendorOAuthClientIds[regionToAFRegion(listing.prodOrgRegion)] = [listing.oauthClientId];

	// Configoverrides > permissions
	if (listing.defaultPermissionsPolicy.length > 0)
		finalOutput.configOverrides.properties.permissions = listing.defaultPermissionsPolicy.join(',');

	// App location
	switch (listing.applicationLocation) {
		case AppLocation.appsMenu:
			break;
		case AppLocation.directoryMenu:
			finalOutput.configOverrides.properties.displayType = 'standalone';
			finalOutput.configOverrides.properties.featureCategory = 'directory';
			break;
		case AppLocation.performanceMenu:
			finalOutput.configOverrides.properties.displayType = 'standalone';
			finalOutput.configOverrides.properties.featureCategory = 'contactCenterInsights';
			break;
		case AppLocation.sidecar:
			finalOutput.configOverrides.properties.displayType = 'interactionWidget';
			break;
		default:
			break;
	}

	return JSON.stringify(finalOutput, null, 2);
}

export function serializeVendorJSON(listing: ListingDetails): string {
	const finalOutput: VendorOutput = {
		vendor: listing.vendorName,
		orgId: listing.orgId,
		region: regionToAFRegion(listing.prodOrgRegion),
	};
	return JSON.stringify(finalOutput, null, 2);
}

export function serializePropertiesJSON(listing: ListingDetails): string {
	try {
		const ret = JSON.parse(JSON.stringify(propertiesSchemaJSON)); //deep copy
		ret.title = `${listing.name} Properties`;
		ret.description = `Defines the basic configuration for ${listing.name}`;
		ret.properties.url.default = listing.defaultAppURL;

		return JSON.stringify(ret, null, 2);
	} catch (e) {
		return 'error building JSON';
	}
}

export function serializeAdvancedJSON(listing: ListingDetails): string {
	try {
		const ret = JSON.parse(JSON.stringify(advancedSchemaJSON)); //deep copy
		ret.title = `${listing.name} Advanced Configuration`;
		ret.description = `Defines the advanced configuration for ${listing.name}`;

		return JSON.stringify(ret, null, 2);
	} catch (e) {
		return 'error building JSON';
	}
}
