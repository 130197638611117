import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { DxButton, DxTextbox, DxLabel, DxItemGroupItem, DxItemGroup, DxCheckbox } from 'genesys-react-components';
import { AppLocation, ListingDetails } from './types';
import AlertBlock from '../../markdown/alertblock/AlertBlock';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import ImageUploader from './ImageUploader';
import Validator from './validation/Validator';
import MarkdownDisplay from '../../markdowndisplay/MarkdownDisplay';
import ValidationFieldContainer from './validation/ValidationFieldContainer';
import { serializeAdvancedJSON, serializeIntegration, serializePropertiesJSON, serializeVendorJSON } from './ListingSerializer';
import AccountSwitcher from '../../accountswitcher/AccountSwitcher';
import { camelize, getSvgDimensions } from './utils';

import './PremiumAppSubmission.scss';

const LISTING_URL_ROOT = 'https://appfoundry.genesys.com/filter/genesyscloud/listing/';

const sandboxOptionItems: DxItemGroupItem[] = [
	{ label: 'allow-forms', value: 'allow-forms', isSelected: true },
	{ label: 'allow-modals', value: 'allow-modals', isSelected: true },
	{ label: 'allow-popups', value: 'allow-popups', isSelected: true },
	{ label: 'allow-presentation', value: 'allow-presentation' },
	{ label: 'allow-same-origin', value: 'allow-same-origin', isSelected: true },
	{ label: 'allow-scripts', value: 'allow-scripts', isSelected: true },
	{ label: 'allow-downloads', value: 'allow-downloads', isSelected: true },
];

const permissionItems: DxItemGroupItem[] = [
	{ label: 'Camera', value: 'camera' },
	{ label: 'Clipboard Write', value: 'clipboard-write' },
	{ label: 'Display Capture', value: 'display-capture' },
	{ label: 'Full Screen', value: 'fullscreen' },
	{ label: 'Geolocation', value: 'geolocation' },
	{ label: 'Microphone', value: 'microphone' },
];

const applicationLocationItems: DxItemGroupItem[] = [
	{ label: 'Directory Menu', value: AppLocation.directoryMenu },
	{ label: 'Performance Menu', value: AppLocation.performanceMenu },
	{ label: 'Apps Menu', value: AppLocation.appsMenu, isSelected: true },
	{ label: 'Sidecar (aka Widget panel)', value: AppLocation.sidecar },
];

export default function PremiumAppSubmission() {
	const selectedAccount = useRecoilValue(selectedAccountAtom);
	const [errors, setErrors] = useState<{ [key: string]: Array<string> }>({});
	const [fieldOnFocus, setFieldOnFocus] = useState<string>();
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({
		defaultSandboxOptions: ['allow-forms', 'allow-modals', 'allow-same-origin', 'allow-scripts', 'allow-downloads', 'allow-popups'],
		defaultPermissionsPolicy: [],
		applicationLocation: AppLocation.appsMenu,
	});

	// Validate fields on formValues update
	// Only validates fields that have been updated so that untouched fields will not show error immediately.
	// Validation of required fields/blank fields are validated instead on separate method.
	useEffect(() => {
		if (!formValues) return;

		const newErrors: { [key: string]: Array<string> } = {};
		const validator: Validator = new Validator(newErrors);

		validator.validateStringLength('name', formValues.name, 3, 50);

		validator.validateStringLength('description', formValues.description, 1, 75);

		validator.validateStringLength('vendorName', formValues.vendorName, 1, 75);
		validator.validateURL('vendorWebsite', formValues.vendorWebsite);

		validator.validateURL('marketplaceURL', formValues.marketplaceURL);
		validator.validateStringPrefix('marketplaceURL', formValues.marketplaceURL, LISTING_URL_ROOT);

		validator.validateURL('helpURL', formValues.helpURL);

		// Use prefix instead of URL validation, because it fail due to dynamic parameters (ie {{pcLangTag}})
		validator.validateStringPrefix('defaultAppURL', formValues.defaultAppURL, 'https://');
		validator.validateStringContains('defaultAppURL', formValues.defaultAppURL, ['{{gcLangTag}}', '{{gcHostOrigin}}', '{{gcTargetEnv}}']);

		// validator.validateIntRange('uniquePermissions', listingDetails.uniquePermissions, 0, 3);

		validator.validateURL('hostedAppIcon', formValues.hostedAppIcon);

		validator.validateUUID('oauthClientId', formValues.oauthClientId);

		validator.validateURL('tosURL', formValues.tosURL);

		// Installation App Icon
		// Add if there are any upload errors
		if (formValues.installationAppIcon?.error) {
			validator.addErrorMessage('installationAppIcon', formValues.installationAppIcon?.error);
		}
		// Check if square
		const resolution: string = formValues.installationAppIcon?.resolution;
		if (resolution) {
			const resArr = resolution.split('x');
			if (resArr.length !== 2 || resArr[0] !== resArr[1])
				validator.addErrorMessage('installationAppIcon', 'Icon should have 1:1 dimensions (eg. 128x128)');
		}

		// Optional Fields
		validator.validateURL('faqURL', formValues.faqURL, false);
		validator.validateURL('privacyPolicyURL', formValues.privacyPolicyURL, false);
		validator.validateURI('supportContactURL', formValues.supportContactURL, true);
		validator.validateURI('salesContactURL', formValues.salesContactURL, true);
		validator.validateURL('additionalHelpURL', formValues.additionalHelpURL, false);

		// explicitly compared to 'false' to skip default 'undefined'
		if (formValues.accessControl === false) {
			validator.addErrorMessage('accessControl', 'Access control acknowledgement is required. Please refer to the notes below.');
		}

		setErrors(newErrors);
	}, [formValues]);

	// Helper function to update the listingDetail state
	const updateListingDetail = (name: string, value: any) => {
		setFormValues((prevDetails) => {
			const tmpObj = Object.assign({}, prevDetails);

			// Prevent the initial loading of field component where it sets the property to empty(text) or false(bool).
			// Initial values are needed to be undefined if user has not touched it yet
			if (prevDetails[name] === undefined && (value === '' || value === false)) return tmpObj;

			tmpObj[name] = value;

			return tmpObj;
		});
	};

	// Append an error message to a field. For validations that is not called
	// on every field update ie on attempted submit
	const addError = (fieldKey: string, error: string) => {
		setErrors((prevErrors) => {
			const tmpObj = Object.assign({}, prevErrors);

			if (!tmpObj[fieldKey]) tmpObj[fieldKey] = [];
			tmpObj[fieldKey].push(error);

			return tmpObj;
		});
	};

	// Download the listing details as a zip file
	const downloadListingData = async (data: ListingDetails) => {
		if (!formValues) return;

		const appId = '{{app_id}}';
		const zip = new JSZip();

		// Change file name of attached svg to camel-cased integration name.
		// Do it here instead of on SVG upload in case svg is uploaded
		// before name is filled out
		if (data.installationAppIcon?.file) {
			const appIcon = data.installationAppIcon.file;
			const text = await appIcon.text();

			data.installationAppIcon.name = `${camelize(data.name)}.svg`;
			zip.file(data.installationAppIcon.name, text);
		}

		// Zip contents
		zip.file(`integration.json`, serializeIntegration(data));
		zip.file(`vendor.json`, serializeVendorJSON(data));
		zip.file(`premium-app-${appId}-properties.json`, serializePropertiesJSON(data));
		zip.file(`premium-app-${appId}-advanced.json`, serializeAdvancedJSON(data));

		// Download zip
		const content = await zip.generateAsync({ type: 'blob' });
		FileSaver.saveAs(content, `premium-app-{{app_id}}.zip`);
	};

	// Checks that all required string fields have values in them
	const isRequiredFieldsFilled = (data: ListingDetails) => {
		let ret = true; // overwritten when errors are found

		// Installation App Icon should exist
		if (!data.installationAppIcon?.file) {
			updateListingDetail('installationAppIcon', {
				error: 'Installation App Icon is required',
			});
			ret = false;
		}

		// access control
		if (!data.accessControl) {
			updateListingDetail('accessControl', false);
			ret = false;
		}

		// required fields
		if (
			data.name.length === 0 ||
			data.description.length === 0 ||
			data.vendorName.length === 0 ||
			data.vendorWebsite.length === 0 ||
			data.marketplaceURL.length === 0 ||
			data.helpURL.length === 0 ||
			data.defaultAppURL.length === 0 ||
			data.hostedAppIcon.length === 0 ||
			data.oauthClientId.length === 0 ||
			data.tosURL.length === 0
		)
			ret = false;

		return ret;
	};

	// When the user clicks to submit the form
	// Does a final validation for some fields
	const submitForm = async () => {
		if (!selectedAccount) return;

		// Make sure all required fields are filled out
		// This will also trigger errors for required fields that were left blank
		const tmpObj: ListingDetails = {
			...{
				name: '',
				description: '',
				vendorName: '',
				vendorWebsite: '',
				marketplaceURL: '',
				helpURL: '',
				defaultAppURL: '',
				defaultSandboxOptions: ['allow-forms', 'allow-modals', 'allow-same-origin', 'allow-scripts'],
				defaultPermissionsPolicy: [],
				applicationLocation: AppLocation.appsMenu,
				hostedAppIcon: '',
				oauthClientId: '',
				tosURL: '',
				accessControl: false,
				prodOrgRegion: selectedAccount.region,
				orgId: selectedAccount.me?.organization?.id || 'ERROR',
			},
			...formValues,
		};

		// Add period to description if not yet included
		if (tmpObj.description.length > 0 && !tmpObj.description.endsWith('.')) {
			tmpObj.description += '.';
		}

		setFormValues(tmpObj);

		// FINAL Validations
		let allPass = true;

		// Required fields that weren't touched will start showing errors
		if (!isRequiredFieldsFilled(tmpObj)) allPass = false;

		// Validate marketplace URL if valid
		if (tmpObj.marketplaceURL.includes(LISTING_URL_ROOT)) {
			const listingId = tmpObj.marketplaceURL.replace(LISTING_URL_ROOT, '');
			if (!(await isListingValid(listingId))) {
				addError('marketplaceURL', `${listingId} is an invalid listing ID. `);
				allPass = false;
			}
		}

		if (!allPass) return;

		downloadListingData(tmpObj);
	};

	// Special validation for marketplaceURL to check that listing id is valid
	const isListingValid = async (listingId: string): Promise<boolean> => {
		return fetch(`https://api.mypurecloud.com/api/v2/marketplace/listings/${listingId}`)
			.then((res) => {
				return res.ok;
			})
			.catch((e) => {
				console.error(e);
				return false;
			});
	};

	// Prevent racing between fields' onFocus and onBlur callbacks.
	// Only reset state 'fieldOnFocus' if current value is still the field itself.
	const blurField = (name: string) => {
		if (fieldOnFocus !== name) return;

		setFieldOnFocus('');
	};

	const getForm = () => {
		// Require account login before accessing the form
		if (!selectedAccount) {
			return (
				<AlertBlock title="Login Required" alertType="critical">
					Please login to your Genesys Cloud development org using the account selector before accessing the form (top-right).
				</AlertBlock>
			);
		}

		// The form itself
		return (
			<div>
				<AccountSwitcher mode="mini" />
				<form className="submission-form">
					{/* App Name */}
					<ValidationFieldContainer errors={errors} name="name">
						<DxTextbox
							inputType="text"
							label="App Name"
							placeholder="My Amazing App"
							clearButton={true}
							onChange={(value: string) => {
								updateListingDetail('name', value);
							}}
							description="Required. The name of your integration app"
						/>
					</ValidationFieldContainer>
					{/* App Description */}
					<ValidationFieldContainer
						errors={errors}
						name="description"
						isFocused={fieldOnFocus === 'description'}
						info={`:::info
**Note:**
- Description should start with a verb in present tense.
- Single sentence
- 75 characters maximum
- *Example(s):*
    - Enables Genesys Cloud users to chat using Skype for Business.
    - Uses static and custom actions to act on data in CRM.
    - Embeds third-party web pages in the Genesys Cloud UI.
            :::`}
					>
						<DxTextbox
							inputType="text"
							label="App Description"
							placeholder="Enables call center integration for end users."
							clearButton={true}
							onChange={(value: string) => updateListingDetail('description', value)}
							description="Required. Description of your integration app"
							onFocus={() => {
								setFieldOnFocus('description');
							}}
							onBlur={() => {
								blurField('description');
							}}
						/>
					</ValidationFieldContainer>
					{/* Vendor Name */}
					<ValidationFieldContainer errors={errors} name="vendorName" isFocused={fieldOnFocus === 'vendorName '}>
						<DxTextbox
							inputType="text"
							label="Vendor Name"
							placeholder="My Company"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('vendorName', value)}
							description="Required. Name of the vendor/company."
							onFocus={() => {
								setFieldOnFocus('vendorName');
							}}
							onBlur={() => {
								blurField('vendorName');
							}}
						/>
					</ValidationFieldContainer>
					{/* Vendor Website */}
					<ValidationFieldContainer errors={errors} name="vendorWebsite" isFocused={fieldOnFocus === 'vendorWebsite '}>
						<DxTextbox
							inputType="text"
							label="Vendor Website"
							placeholder="https://the-company.com"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('vendorWebsite', value)}
							description="Required. Your company's website."
							onFocus={() => {
								setFieldOnFocus('vendorWebsite');
							}}
							onBlur={() => {
								blurField('vendorWebsite');
							}}
						/>
					</ValidationFieldContainer>
					{/* Marketplace Uri */}
					<ValidationFieldContainer
						errors={errors}
						name="marketplaceURL"
						isFocused={fieldOnFocus === 'marketplaceURL '}
						info={`:::info
**Note:**
- The URL to your listing in the [AppFoundry Marketplace](https://appfoundry.genesys.com/filter/genesyscloud/listing/)
            :::`}
					>
						<DxTextbox
							inputType="text"
							label="AppFoundry Listing URL"
							placeholder="https://appfoundry.genesys.com/filter/genesyscloud/listing/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('marketplaceURL', value)}
							description="The AppFoundry marketplace listing URL."
							onFocus={() => {
								setFieldOnFocus('marketplaceURL');
							}}
							onBlur={() => {
								blurField('marketplaceURL');
							}}
						/>
					</ValidationFieldContainer>
					{/* Help URL */}
					<ValidationFieldContainer errors={errors} name="helpURL">
						<DxTextbox
							inputType="text"
							label="Help URL"
							placeholder="https://www.myapp.com/help"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('helpURL', value)}
							description="Required. Help URL for your app"
						/>
					</ValidationFieldContainer>
					{/* Installation App Icon */}
					<ValidationFieldContainer errors={errors} name="installationAppIcon">
						<DxLabel label="Installation App Icon">
							<ImageUploader
								onChange={(file) => {
									if (!file) {
										updateListingDetail('installationAppIcon', {});
										return;
									}

									getSvgDimensions(file)
										.then((dimensions) => {
											updateListingDetail('installationAppIcon', {
												file: file,
												name: file.name,
												resolution: `${dimensions[0]}x${dimensions[1]}`,
											});
										})
										.catch((e: Error) => {
											updateListingDetail('installationAppIcon', {
												error: e.message,
											});
										});
								}}
								accepts={['svg']}
							/>
						</DxLabel>
					</ValidationFieldContainer>
					<MarkdownDisplay
						markdown={`:::info
**Note:**
- File type: **SVG** only
- Ratio **MUST** be 1:1 (eg. 96px x 96px)
- Must have transparent background
            :::`}
					/>
					{/* Application Setttings */}
					{/* Default Application URL */}
					<ValidationFieldContainer
						errors={errors}
						name="defaultAppURL"
						isFocused={fieldOnFocus === 'defaultAppURL'}
						info={`:::info
**Note:**
- Regional URLs are NOT supported based on Genesys Cloud Region
- Dynamic values \`{{gcLangTag}}\`, \`{{gcHostOrigin}}\` and \`{{gcTargetEnv}}\` are required to be in the URL. [Read More](https://developer.genesys.cloud/platform/integrations/client-apps/client-app-sdk-guide) 
- Supported Genesys Cloud languages are located [here](https://help.mypurecloud.com/articles/purecloud-supported-languages/)
            :::`}
					>
						<DxTextbox
							inputType="text"
							label="Publicly Hosted Install Wizard URL"
							placeholder="https://myapp.com?langTag={{gcLangTag}}&hostOrigin={{gcHostOrigin}}&targetEnv={{gcTargetEnv}}"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('defaultAppURL', value)}
							description="Required. URL of your application."
							onFocus={() => {
								setFieldOnFocus('defaultAppURL');
							}}
							onBlur={() => {
								blurField('defaultAppURL');
							}}
						/>
					</ValidationFieldContainer>
					{/* # of Unique Permissions
            <ValidationFieldContainer errors={errors} name="uniquePermissions" isFocused={fieldOnFocus==='uniquePermissions'}
                info={`:::info
**Note:**
- Each permission will be associated to a Genesys Cloud License. We will work with you creating your Premium Client Application to determine the RIGHT number of these!
- Many applications will only require 1
            :::`}
            >
                <DxTextbox
                    inputType="integer"
                    label="Number of Unique Permissions (Up to 3)"
                    initialValue="1"
                    clearButton={true}
                    onChange={(value: string) => updateListingDetail('uniquePermissions', parseInt(value))}
                    onFocus={() => {setFieldOnFocus('uniquePermissions');}}
                    onBlur={() => {blurField('uniquePermissions');}}
                />
            </ValidationFieldContainer> */}
					{/* Default Sandbox Options */}
					<DxItemGroup
						title="IFrame Sandbox Options"
						items={sandboxOptionItems}
						format="checkbox"
						// onItemChanged={(item, isSelected) => console.log(`Radio: ${item.label} (${item.value}) -> ${isSelected}`)}
						onItemsChanged={(items) => {
							const selectedItems = items.filter((item) => item.isSelected).map((item) => item.item.value);
							updateListingDetail('defaultSandboxOptions', selectedItems);
						}}
					/>
					<MarkdownDisplay
						markdown={`:::info
**Note:** Iframe sandbox options for the your integration. [Read more](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox).
            :::`}
					/>
					{/* Default PermissionsPolicy Values */}
					<DxItemGroup
						title="Permissions Policy Values"
						items={permissionItems}
						format="checkbox"
						// onItemChanged={(item, isSelected) => console.log(`Radio: ${item.label} (${item.value}) -> ${isSelected}`)}
						onItemsChanged={(items) => {
							const selectedItems = items.filter((item) => item.isSelected).map((item) => item.item.value);
							updateListingDetail('defaultPermissionsPolicy', selectedItems);
						}}
					/>
					<MarkdownDisplay
						markdown={`:::info
**Note:** 
- Set of features to control application permissions (See [Permissions Policy](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Feature-Policy)). 
- Only the minimum required permissions should be added, as allowing permissions can open the app to security concerns.
            :::`}
					/>
					{/* Application Location */}
					<DxItemGroup
						title="Application Location"
						items={applicationLocationItems}
						format="radio"
						onItemChanged={(item, isSelected) => {
							if (isSelected) updateListingDetail('applicationLocation', item.value);
						}}
					/>
					{/* Hosted App Icon*/}
					<ValidationFieldContainer
						errors={errors}
						name="hostedAppIcon"
						isFocused={fieldOnFocus === 'hostedAppIcon'}
						info={`:::info 
**Note:**
- MUST be a vendor hosted URL
            :::`}
					>
						<DxTextbox
							inputType="text"
							label="Hosted App Icon"
							placeholder="https://www.myapp.com/loading-icon.png"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('hostedAppIcon', value)}
							description="Shown when app is loading; or in the sidecar menu"
							onFocus={() => {
								setFieldOnFocus('hostedAppIcon');
							}}
							onBlur={() => {
								blurField('hostedAppIcon');
							}}
						/>
						{formValues.hostedAppIcon ? (
							<div className="img-preview">
								<p>Preview:</p>
								<img src={formValues.hostedAppIcon} alt="Preview" />
							</div>
						) : undefined}
					</ValidationFieldContainer>
					{/* OAuth Client ID*/}
					<ValidationFieldContainer errors={errors} name="oauthClientId">
						<DxTextbox
							inputType="text"
							label="OAuth Client ID"
							placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('oauthClientId', value)}
							description="Code Authorization or Implicit Grant ID ONLY"
						/>
					</ValidationFieldContainer>
					{/* TOS URL*/}
					<ValidationFieldContainer errors={errors} name="tosURL">
						<DxTextbox
							inputType="text"
							label="Terms of Service URL"
							placeholder="https://www.myapp.com/tos"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('tosURL', value)}
							description="Required"
						/>
					</ValidationFieldContainer>
					{/* FAQ URL */}
					<ValidationFieldContainer errors={errors} name="faqURL">
						<DxTextbox
							inputType="text"
							label="FAQ URL"
							placeholder="https://www.myapp.com/faq"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('faqURL', value)}
							description="Optional"
						/>
					</ValidationFieldContainer>
					{/* Privacy Policy URL */}
					<ValidationFieldContainer errors={errors} name="privacyPolicyURL">
						<DxTextbox
							inputType="text"
							label="Privacy Policy URL"
							placeholder="https://www.myapp.com/privacy-policy"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('privacyPolicyURL', value)}
							description="Optional"
						/>
					</ValidationFieldContainer>
					{/* Support Contact URL */}
					<ValidationFieldContainer errors={errors} name="supportContactURL">
						<DxTextbox
							inputType="text"
							label="Support Contact"
							placeholder="https://www.myapp.com/support OR mailto:support@myapp.com"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('supportContactURL', value)}
							description="Optional"
						/>
					</ValidationFieldContainer>
					{/* Sales Contact URL */}
					<ValidationFieldContainer errors={errors} name="salesContactURL">
						<DxTextbox
							inputType="text"
							label="Sales Contact"
							placeholder="https://www.myapp.com/sales OR mailto:sales@myapp.com"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('salesContactURL', value)}
							description="Optional"
						/>
					</ValidationFieldContainer>
					{/* Additional Help URLs */}
					<ValidationFieldContainer errors={errors} name="additionalHelpURL">
						<DxTextbox
							inputType="text"
							label="Additional Help URL"
							placeholder="https://www.myapp.com/additional-help"
							clearButton={true}
							onChange={(value: string) => updateListingDetail('additionalHelpURL', value)}
							description="Optional"
						/>
					</ValidationFieldContainer>
					{/* Access Control */}
					<ValidationFieldContainer errors={errors} name="accessControl">
						<DxCheckbox
							label="I acknowledge our application will enforce all Genesys Cloud provided permissions for our Premium Client Application."
							itemValue="access-control"
							onCheckChanged={(value: boolean) => {
								updateListingDetail('accessControl', value);
							}}
						/>
					</ValidationFieldContainer>
					<MarkdownDisplay
						markdown={`:::info 
**Note:**
- Genesys Cloud provides unique permission(s) per Premium Client Application to control visibility inside Genesys Cloud.
- By checking the box, you are acknowledging that the app uses access control to enforce required licenses and permissions.
            :::`}
					/>
					{/* Instructions */}
					<MarkdownDisplay
						markdown={`
**Instructions**:

1. After completing the form, download the zip file by clicking the **Download Data** button.
2. Log into the Genesys Ascend Partner Portal and from the **Support** menu open a **Partner Hub Request** case. In the case let the Partner Hub team know that you are submitting your premium app install wizard details and attach the zip file to the case.
					`}
					/>

					{/* BUTTONS */}
					<DxButton type="primary" onClick={() => submitForm()} disabled={Object.keys(errors).length !== 0}>
						Download Data
					</DxButton>
					{Object.keys(errors).length !== 0 ? (
						<AlertBlock title="Form error" alertType="critical">
							Some fields are incomplete or contain errors. Please review before resubmitting the form.
						</AlertBlock>
					) : undefined}
				</form>
			</div>
		);
	};

	return <div className="submission-container">{getForm()}</div>;
}
